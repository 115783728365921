import * as ReactDOM from 'react-dom';

import App from './app/app';
import { initTrackEvent } from '@coverright/shared/analytics';

initTrackEvent('partners-widget');

(window as any).CoverRightWidget = {
  mount: (props: any) => {
    const el = document.getElementById('cover-right-widget');
    if (el) {
      ReactDOM.render(<App {...props} />, el);
    }
  },
  unmount: () => {
    const el = document.getElementById('cover-right-widget');
    if (el) {
      ReactDOM.unmountComponentAtNode(el as any);
    }
  }
} as any

 // (window as any).CoverRightWidget.mount({});
