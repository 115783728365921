import React from "react";
import {useLazyQuery} from "@apollo/client";
import {gql} from "@apollo/client";
import {useFormik} from "formik";
import * as yup from "yup";
import { SelectInput, TextInput } from "@coverright/ui/inputs";
import { createStyles, makeStyles } from '@mui/styles';
import { Box, InputAdornment, MenuItem, Popover, Theme } from '@mui/material';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      '& .MuiOutlinedInput-input': {
        fontSize: '12px',
        fontWeight: 500,
        padding: '12px 15px 10px!important',
        zIndex: 1,
        lineHeight: 'normal',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        marginTop: 4,
        height: 36,
      },
    },
    paper: {
      '& li.MuiListItem-root': {
        fontSize: 12,
      }
    },
    andorment: {
      position: 'absolute',
      right: 8
    }
  }),
);

interface ZipInputProps {
  onSubmit?: (values: ZipFormValues) => void,
  onChange?: (values: ZipFormValues) => void,
  zip?: string,
  county?: string,
  label?: string,
  className?: string
}

const validationSchema = yup.object({
  zip: yup
    .string()
    .matches(/^[0-9]{5}$/, 'Please enter a valid zip code')
    .required('Enter 5 digit zip code'),
  county: yup.string(),
});

interface ZipFormValues {
  zip: string,
  county: string,
}

const ZipInput = (props: ZipInputProps) => {
  const classes = useStyles();

  const [getCity, data] = useLazyQuery<ICityResponse, {zip: string}>(gql(cityRequest), {
    onCompleted: () => {
      /*
      if (!GetLicensedStates().includes(data.data?.plans_zip_county_fips[0]?.state || '')) {
        setOpen(true);
      }
      */
    },
    fetchPolicy: 'no-cache',
  });
  const [cities, setCities] = React.useState<ICity[]>([]);
  const formik = useFormik({
    initialValues: {
      zip: props.zip || '',
      county: props.county || ''
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      if (props.onSubmit) {
        props.onSubmit(values);
      }
    },
  });
  const ref = React.useRef(null);
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
      if (props.onChange) {
        props.onChange(formik.values);
      }
  }, [formik.values]);

  React.useEffect(() => {
    const zip = formik.values.zip;
    if (zip && zip.length === 5 && !formik.values.county) {
      formik.setFieldValue('county', '');
      getCity({variables: {zip}})
    }
    if (zip.length < 5) {
      formik.setFieldValue('county', '');
      setCities([]);
    }
  }, [formik.values.zip, formik.values.county]);

  React.useEffect(() => {
    setCities(data.data?.plans_zip_county_fips || []);
  }, [data.data, data.called])

  React.useEffect(() => {
    if (data.data?.plans_zip_county_fips.length === 1) {
      formik.setFieldValue('county', data.data?.plans_zip_county_fips[0].countyname as string);
    }
  }, [data.data]);

  React.useEffect(() => {
    setOpen(cities.length > 1 && !formik.values.county);
  }, [cities, formik.values.county]);

  return <>
    <div ref={ref}>
      <TextInput fullWidth
                 hideTick
                 sx={{'& input': {fontSize: '13px!important', fontWeight: 400, boxShadow: 'none!important', border: 'none!important'}}}
                 value={formik.values.zip}
                 onChange={formik.handleChange}
                 error={formik.touched.zip && Boolean(formik.errors.zip)}
                 helperText={formik.touched.zip && formik.errors.zip}
                 size={'small'}
                 onKeyPress={event => {
                   if (event.key === 'Enter' && formik.touched.zip && Boolean(formik.errors.zip)) {
                     formik.submitForm();
                   }
                 }}
                 className={props.className}
                 placeholder={typeof props.label === 'undefined' ? 'Enter your zip code' : props.label}
                 name={'zip'}
                 InputProps={{
                   endAdornment: <InputAdornment sx={{'& p': {maxWidth: 125, overflow: 'hidden', textOverflow: 'ellipsis'}}} position="end" className={classes.andorment}>{formik.values.county}</InputAdornment>,
                   classes: {
                     inputSizeSmall: 'crw-pv-10 crw-pr-0 crw-pl-15'
                   }
                 }}
                 inputProps={{
                   maxLength: 5,
                 }}
                 type={'tel'} />
    </div>
    {!!ref.current && <Popover
      open={open}
      anchorEl={ref.current}
      onClose={() => setOpen(false)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >

      <Box p={1} width={'200px'}>
        <SelectInput
          fullWidth
          sx={{'& div.MuiOutlinedInput-input': {m: '5px 2px 1px!important', p: '8px 15px 6px!important'}}}
          label={'Select county'}
          value={formik.values.county}
          className={`mb-0 ${classes.select}`}
          paperClassName={classes.paper}
          placeholder={'Select county'}
          onChange={(event) => {
            formik.setFieldValue('county', event.target.value as string);
            setOpen(false)
          }}
        >
          {
            cities.map(city => <MenuItem key={city.countyname} value={city.countyname}>{city.countyname}</MenuItem>)
          }
        </SelectInput>
      </Box>
    </Popover>}
  </>;
}

export default ZipInput;

interface ICityResponse {
  plans_zip_county_fips: ICity[]
}

interface ICity {
  city: string,
  state: string,
  countyname: string
}

const cityRequest = `
  query ($zip: String!) {
    plans_zip_county_fips(where: {zip: {_eq: $zip}}) {
      city
      state
      countyname
    }
  }
`
