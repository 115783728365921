import React from "react";
import * as QueryString from "query-string";
import {useParams} from "react-router";
import {
  getCId, getEffectiveDate,
  getMedigapQuoteId,
  getStoredUser,
  handleCollectionChange,
  setMedigapQuoteId
} from '@coverright/utils';
import * as _ from 'lodash';
import { planCategories } from '../category/mgcategory';
import { PlanFilterDataProvider } from './PlanFilterDataContext';
import { MedigapQuoteContext } from '@coverright/shared/contexts';
import {
  Gender,
  MedigapPlanBenefit,
  MedigapPlanName,
  MedigapPlansFilterInput,
  PageInput
} from '@coverright/data-access/types/medigap';
import { useMedigapFilterState } from '@coverright/data-access/medigap';
import moment from 'moment';
import { useLogEvent } from '@coverright/shared/analytics';

enum PlanCategory {
  MostPopular = 'Most Popular',
  CoreBenefits = 'Core benefits',
  Comprehensive = 'Comprehensive',
  CostShare = 'Cost-share',
  HighDeductible = 'High deductible',
}

export interface IPlansFilter extends MedigapPlansFilterInput {
  age: number;
  applyDiscounts?: number;
  applyFees?: number;
  county?: string;
  effectiveDate?: string;
  gender: Gender;
  page?: PageInput;
  selectedPlanCategory: PlanCategory | 'all';
  benefits: MedigapPlanBenefit[];
  planNames: MedigapPlanName[];
  monthlyPlanPremiumRanges: string[];
  companies: string[];
  select?: boolean;
  tobacco: boolean;
  zip: string;
}

interface PlanFilterContextState {
  values?: IPlansFilter,
  switchZip: (zip: string, county: string) => Promise<any>,
  toggleFavorites: () => void,
  switchBenefit: (name: MedigapPlanBenefit) => void,
  switchPlan: (name: MedigapPlanName) => void,
  switchAge: (age: number) => void,
  switchFees: (fee: number) => void,
  switchCategory: (value: PlanCategory) => void,
  switchEffectiveDate: (date?: string) => void,
  switchCompanies: (value: string) => void,
  switchMonthlyPremium: (value: string) => void,
  switchDiscounts: (discount: number) => void,
  switchGender: (name: Gender) => void,
  toggleTobacco: (v: boolean) => void,
  toggleSelect: (v: boolean) => void,
  refresh: () => void,
  reset: () => void,
}

const defaultState: PlanFilterContextState = {
  values: {
    age: 0,
    gender: Gender.M,
    select: false,
    tobacco: false,
    zip: '',
    monthlyPlanPremiumRanges: [],
    planNames: [MedigapPlanName.F, MedigapPlanName.G, MedigapPlanName.N],
    benefits: [],
    companies: [],
    selectedPlanCategory: PlanCategory.MostPopular
  },
  switchZip: (zip: string, county: string) => (zip as any),
  toggleFavorites: () => {},
  switchPlan: (name: MedigapPlanName) => {},
  switchAge: (age: number) => {},
  switchFees: (fee: number) => {},
  switchCompanies: (value: string) => {},
  switchBenefit: (value: MedigapPlanBenefit) => {},
  switchCategory: (value: PlanCategory) => {},
  switchMonthlyPremium: (value: string) => {},
  switchEffectiveDate: (date?: string) => {},
  switchDiscounts: (discount: number) => {},
  switchGender: (name: Gender) => {},
  toggleTobacco: () => {},
  toggleSelect: () => {},
  refresh: () => {},
  reset: () => {},
};

const getEffectiveDateAndAge = (inputDate?: any) => {
  const params = new URLSearchParams(window.location.search);
  const effectiveDate = getEffectiveDate(params.get('effectiveDate') || inputDate);
  let age = Math.floor(moment.duration(moment(effectiveDate).diff(moment(getStoredUser()?.birthDate))).asYears());
  if (age >= 63 && age < 65) {
    age = 65;
  } else if (age < 63) {
    age = 64
  }
  return [effectiveDate, age];
}

export const getDefaultVal = (isHighDeductible: boolean, zip: string, county: string) => {
  const planNames = isHighDeductible ? [MedigapPlanName.Hdf, MedigapPlanName.Hdg] : [MedigapPlanName.F, MedigapPlanName.G, MedigapPlanName.N];
  const [effectiveDate, age] = getEffectiveDateAndAge();

  return {
    zip,
    county,
    age,
    gender: getStoredUser()?.gender ? getStoredUser()?.gender : Gender.F,
    tobacco: getStoredUser()?.tobacco === 'true',
    companies: [],
    monthlyPlanPremiumRanges: [],
    benefits: [],
    planNames,
    selectedPlanCategory: isHighDeductible ? PlanCategory.HighDeductible : PlanCategory.MostPopular,
    effectiveDate,
  }
}

export const PlanFilterContext = React.createContext<PlanFilterContextState>(defaultState);

export const PlanFilterContextProvider = (props: React.PropsWithChildren<PlanFilterProviderProps>) => {

  const quote = React.useContext(MedigapQuoteContext);

  const [filters, setFilters] = React.useState<IPlansFilter>();

  const [getFilterState, filterStateData] = useMedigapFilterState();
  const [filterStateId, setFilterStateId] = React.useState<string>();

  const logEvent = useLogEvent();

  React.useEffect(() => {
    if (filters) {
      sessionStorage.setItem('medigapFilters', JSON.stringify(filters))
    }
  }, [filters])

  const writeZipCountyFromUrlToStorage = () => {
    const params = QueryString.parse(document.location.search);
    if (params.zip && params.county) {
      const storedFilters = sessionStorage.getItem('medigapFilters');
      let data: any = props.initFilter;
      if (storedFilters) {
        data = JSON.parse(storedFilters);
      }
      data.zip = params.zip;
      data.county = params.county;
      if (params.age) {
        data.age = params.age;
      }
      if (params.gender) {
        data.gender = params.gender
      }
      sessionStorage.setItem('medigapFilters', JSON.stringify(data));
    }
  }

  React.useEffect(() => {
    writeZipCountyFromUrlToStorage();
  }, []);

  React.useEffect(() => {

    if (filterStateData.data) {
      const [effectiveDate, age] = getEffectiveDateAndAge(filterStateData.data.medigapFilterState.effectiveDate);
        const updated = {
          tobacco: filterStateData.data.medigapFilterState.tobacco,
          age: age as any,
          zip: filterStateData.data.medigapFilterState.zip,
          county: filterStateData.data.medigapFilterState.county || props.initFilter.county,
          benefits: filterStateData.data.medigapFilterState.benefits || [],
          companies: filterStateData.data.medigapFilterState.companies || [],
          gender: filterStateData.data.medigapFilterState.gender,
          monthlyPlanPremiumRanges: filterStateData.data.medigapFilterState.monthlyPlanPremiumRanges || [],
          planNames: filterStateData.data.medigapFilterState?.planNames?.length ? filterStateData.data.medigapFilterState.planNames : [MedigapPlanName.F, MedigapPlanName.G, MedigapPlanName.N],
          selectedPlanCategory: filterStateData.data.medigapFilterState.planCategory as PlanCategory || PlanCategory.MostPopular,
          effectiveDate: effectiveDate as any
        }
        setFilters(updated);
      }
  }, [filterStateData]);

  React.useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const storedFilters = sessionStorage.getItem('medigapFilters');
    const parsed = JSON.parse(storedFilters || '{}');

    if (quote?.medigapFilterState?.id) {
      setFilterStateId(quote?.medigapFilterState?.id);
    }
    if (params.has('filters')) {
      setFilterStateId(params.get('filters') as string);
      getFilterState({variables: {id: params.get('filters')}});
    } else {
      if (quote?.id) {
        if (quote.medigapFilterState) {
          const [effectiveDate, age] = getEffectiveDateAndAge(quote.medigapFilterState.effectiveDate);
          const newFilters = {
            tobacco: quote.medigapFilterState.tobacco,
            age: age as any,
            zip: quote.medigapFilterState.zip,
            county: quote.medigapFilterState.county || props.initFilter.county,
            benefits: quote.medigapFilterState.benefits || [],
            companies: quote.medigapFilterState.companies || [],
            gender: quote.medigapFilterState.gender,
            monthlyPlanPremiumRanges: quote.medigapFilterState.monthlyPlanPremiumRanges || [],
            planNames: quote.medigapFilterState?.planNames?.length ? quote.medigapFilterState.planNames : props.initFilter.planNames,
            selectedPlanCategory: quote.medigapFilterState.planCategory as PlanCategory || props.initFilter.selectedPlanCategory,
            effectiveDate: effectiveDate as any
          };
          if (!_.isEqual(filters, newFilters)) {
            setFilters(newFilters)
          }
        } else {
          setFilters(props.initFilter)
        }
      } else {
        setFilters(() => {
          if (storedFilters) {
            if (parsed.zip !== props.initFilter.zip) {
              parsed.zip = props.initFilter.zip
            }
            if (parsed.county !== props.initFilter.county) {
              parsed.county = props.initFilter.county
            }
            return parsed;
          } else {
            return props.initFilter
          }
        })
      }
    }
  }, [quote?.id])

  function switchFilter<T>(key: T, filterKey: keyof IPlansFilter) {
    setFilters(prev => {
      if (prev) {
        let newInstance: any = {...prev}
        if (Array.isArray(prev[filterKey])) {
          newInstance[filterKey] = handleCollectionChange<T>(key, prev[filterKey] as any)
        } else {
          if (filterKey === 'selectedPlanCategory') {
            if ((key as any) === 'all') {
              prev.planNames = _.flatMap(planCategories, c => c.plans);
            } else {
              prev.planNames = planCategories.find(c => c.name === key as any)?.plans || [];
            }
          }
          newInstance = {...prev, [filterKey]: prev[filterKey] as any === key ? undefined : key};
        }
        if (newInstance?.zip && newInstance?.county && newInstance?.effectiveDate) {
          saveQuote(newInstance);
        }
        return newInstance;
      }
      return prev;
    })
  }

  const saveQuote = React.useCallback((data?: IPlansFilter) => {
    const medigapFilterState: any = data ? {...data} : {...filters};
    medigapFilterState.planCategory = medigapFilterState.selectedPlanCategory;
    delete medigapFilterState.selectedPlanCategory;
    medigapFilterState.id = filterStateId;

      return quote.save({
        variables: {
          input: {
            cId: getCId(),
            id: getMedigapQuoteId(),
            medigapFilterState,
          }
        }
      }).then((res: any) => setMedigapQuoteId(res?.data?.saveMedigapQuote.mgQuoteId as string));
  }, [filterStateId, filters, ])

  function refresh() {
    setFilters(prev => {
      return prev ? {...prev} : prev;
    })
  }

  const contextValue = React.useMemo<PlanFilterContextState>(() => (
      {
        values: filters,
        reset: () => {
          setFilters(props.initFilter)
        },
        switchZip: (zip: string, county: string) => {
          const updated = {...filters, zip, county};
          setFilters(prev => {
            return prev ? {...prev, ...updated} : prev;
          })
          return saveQuote(updated as any);
        },
        toggleFavorites: () => {
          // todo implement favorites
          /*setFilters(prev => {
            if (prev) {
              return {...prev, onlyFavorite: !prev.onlyFavorite}
            }
            return prev;
          })*/
        },
        toggleSelect: (value: boolean) => {
          switchFilter<boolean>(value,'select')
        },
        switchAge: (value: number) => {
          switchFilter<number>(value,'age')
        },
        toggleTobacco: (value: boolean) => {
          switchFilter<boolean>(value,'tobacco')
        },
        switchDiscounts: (value: number) => {
          switchFilter<number>(value,'applyDiscounts')
        },
        switchFees: (value: number) => {
          switchFilter<number>(value,'applyFees')
        },
        switchEffectiveDate: (date?: string) => {
          switchFilter<string | undefined>(date,'effectiveDate')
        },
        switchGender: (value: Gender) => {
          switchFilter<Gender>(value,'gender')
        },
        switchPlan: (value: MedigapPlanName) => {
          logEvent('MG marketplace - choose plan type filter', {source: 'V2', value: value as any})
          switchFilter<MedigapPlanName>(value,'planNames')
        },
        switchCompanies: (value: string) => {
          logEvent('MG marketplace - choose company filter', {source: 'V2', value: value as any})
          switchFilter<string>(value,'companies')
        },
        switchBenefit: (value: MedigapPlanBenefit) => {
          switchFilter<string>(value,'benefits')
        },
        switchMonthlyPremium: (value: string) => {
          switchFilter<string>(value,'monthlyPlanPremiumRanges')
        },
        switchCategory: (value: PlanCategory) => {
          switchFilter<string>(value,'selectedPlanCategory')
        },
        refresh
      }
    )
  , [filters]);

  return <PlanFilterContext.Provider value={contextValue}>
    {props.children}
  </PlanFilterContext.Provider>

}

interface PlanFilterProviderProps {
  initFilter: IPlansFilter
}
