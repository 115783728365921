import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import {gql} from "@apollo/client";
import { PageableMedigapPlanOutput, QueryMedigapPlansArgs } from '@coverright/data-access/types/medigap';

export function useMedigapPlans(options?: LazyQueryHookOptions<IPlansOutput, QueryMedigapPlansArgs>) {

  return useLazyQuery<IPlansOutput, QueryMedigapPlansArgs>(gql(getPlans), {
    fetchPolicy: "no-cache",
    errorPolicy: 'all',
    ...options
  });
}

export function useMedigapPlansTotal(options?: LazyQueryHookOptions<IPlansTotalOutput, QueryMedigapPlansArgs>) {

  return useLazyQuery<IPlansTotalOutput, QueryMedigapPlansArgs>(gql(getPlansTotal), {
    fetchPolicy: "no-cache",
    errorPolicy: 'all',
    ...options
  });
}

interface IPlansOutput {
  medigapPlans: PageableMedigapPlanOutput
}

interface IPlansTotalOutput {
  medigapPlans: {totalElements: number}
}

const getPlans = `
query($filter: MedigapPlansFilterInput!, $page: PageInput!, $sort: [MedigapPlansSortInput!]) {
    medigapPlans(filter: $filter, page: $page, sort: $sort) {
      data {
        amBestRating
        householdDiscount
        key
        monthlyPremium
        orgName
        parentOrgName
        title
        subTitle
        naic
        spRating
        ratingClass
        planName
        showAdditionalBenefitsDescription
      }
      totalElements
    }
}
`;

const getPlansTotal = `
query($filter: MedigapPlansFilterInput!, $page: PageInput!, $sort: [MedigapPlansSortInput!]) {
    medigapPlans(filter: $filter, page: $page, sort: $sort) {
      totalElements
    }
}
`;
