import { getCId, getVid } from '@coverright/utils/CId';
// @ts-ignore
import metadata from '../../../../../metadata.json';

export function initTrackEvent(productName: string) {
  let count = 0;
  const interval = setInterval(() => {
    if (window) {
      if (getVid() || count > 10) {
        clearInterval(interval);
      }
      Object.assign(window, {
        c_id: getCId(),
        product: productName,
        version: `${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision}`
      })
    }
    count++;
  }, 500)

}
