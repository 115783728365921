import React from "react";
import * as yup from "yup";
import {AppStateActionTypes, AppStateContext} from "../shared/AppStateContext";
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import { Button, MenuItem, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Gender } from '@coverright/data-access/types/medigap';
import BackButton from '../shared/BackButton';
import { environment } from '../environments/environment';
import { SelectInput } from '@coverright/ui/inputs';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      width: 125,
      height: 24,
      maxWidth: 125,
      maxHeight: 24,
    },
    select: {
      '& .MuiOutlinedInput-input': {
        fontSize: '12px',
        fontWeight: 500,
        padding: '12px 15px 10px!important',
        zIndex: 1,
        lineHeight: 'normal',
        boxSizing: 'initial!important'
      },
      '& .MuiOutlinedInput-notchedOutline': {
        marginTop: 4,
        height: 36,
      },
    },
    paper: {
      '& li.MuiListItem-root': {
        fontSize: 12,
      }
    },
    wrapper: {
      [theme.breakpoints.up('sm')]: {
        display: 'flex',
      },
      display: 'block',
    },
    textWrapper: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      [theme.breakpoints.up('sm')]: {
        width: 260,
      },
    },
    rightSide: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      [theme.breakpoints.up('sm')]: {
        alignItems: 'flex-end'
      },
    },
    inputsWrapper: {
      marginBottom: 16,
      [theme.breakpoints.down('xs')]: {
        marginTop: 16
      },
      [theme.breakpoints.up('sm')]: {
        width: 330,
        display: 'flex',
      },
    }
  }),
);

const validationSchema = yup.object({
  zip: yup
    .string()
    .matches(/^[0-9]{5}$/, 'Please enter a valid zip code')
    .required('Enter 5 digit zip code'),
});

const MedigapFilters = () => {
  const classes = useStyles();
  const [, dispatch] = React.useContext(AppStateContext);
  const [age, setAge] = React.useState(65);
  const [gender, setGender] = React.useState(Gender.M);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));

  const onClick = () => {
    dispatch({type: AppStateActionTypes.UPDATE, payload: {age, gender, currentStep: 3}})
  }

  return <div className={classes.wrapper}>
    <div className={`crw-flex-column ${!phone ? 'crw-mr-55 crw-flex-space-between' : 'crw-mb-20'}`}>
      <div>
        <Typography className={'crw-dark-green crw-fs-20 crw-medium'}>Select your plan type:</Typography>
        <Typography className={'crw-bold crw-fs-18'} color={'textPrimary'}>Choose the type of plan you are {desktop ? <br /> : ''}looking to get quotes for</Typography>
      </div>
      <div>
        <BackButton
          startIcon={<img src={environment.imagesUrl + "/img/arrow-left.svg"}/>}
          onClick={() => dispatch({type: AppStateActionTypes.BACK})}>
          Back
        </BackButton>
      </div>
    </div>
    <div className={classes.rightSide}>
      <div className={classes.inputsWrapper}>
        <div className={`crw-flex crw-flex-align-center crw-mr-20 ${phone ? 'crw-mb-10' : ''}`}>
          <Typography sx={{marginTop: '10px'}} className={`crw-medium crw-fs-14 crw-mr-10 ${phone ? 'crw-w-50' : ''}`}>Age</Typography>
          <SelectInput label={''}
                       sx={{'& .MuiSelect-iconOutlined': {marginTop: '2px'}, '& div.MuiOutlinedInput-input': {m: '5px 2px 1px!important', p: '4px 15px 2px!important'}}}
                       className={`crw-mb-0 crw-w-105 ${classes.select}`}
                       paperClassName={classes.paper}
                       placeholder={'Select your age'}
                       value={age}
                       onChange={(event) => {
                         setAge(event.target.value as number)
                       }}
          >
            <MenuItem key={64} value={64}>64-</MenuItem>
            {Array(50).fill(2).map((p, i) => <MenuItem key={i} value={i + 65}>{i + 65}</MenuItem>)}
          </SelectInput>
        </div>
        <div className={'crw-flex crw-flex-align-center'}>
          <Typography sx={{mt: '10px'}} color={'textPrimary'} className={'crw-medium crw-fs-14 crw-mr-10'}>Gender</Typography>
          <SelectInput label={''}
                       sx={{'& .MuiSelect-iconOutlined': {marginTop: '2px'}, '& div.MuiOutlinedInput-input': {m: '5px 2px 1px!important', p: '4px 15px 2px!important'}}}
                       className={`crw-mb-0 crw-w-105 ${classes.select}`}
                       paperClassName={classes.paper}
                       placeholder={'Select your gender'}
                       value={gender}
                       onChange={(event) => {
                         setGender(event.target.value as Gender)
                       }}
          >
            <MenuItem value={'M'}>Male</MenuItem>
            <MenuItem value={'F'}>Female</MenuItem>
          </SelectInput>
        </div>
      </div>
      <SeeRatesButton variant={'rounded'} sx={{fontSize: 18, lineHeight: '31px'}} className={'crw-mb-10'} onClick={() => onClick()}>See rates</SeeRatesButton>
      <div className={'crw-flex crw-flex-align-center crw-flex-justify-end'}>
        <Typography className={'crw-fs-10 crw-mr-10'} color={'textPrimary'}>Powered by:</Typography>
        <img src={environment.imagesUrl + '/img/Logo.svg'} className={classes.logo} />
      </div>
    </div>
  </div>;
}

export default MedigapFilters;

const SeeRatesButton = withStyles({
  root: {
    transition: 'all 0.3s',
    maxWidth: 339,
    width: '100%',
    whiteSpace: 'nowrap',
    '&:focus': {
    },
  },
  rounded: {
    color: 'white',
    backgroundColor: '#FF785A!important',
    boxShadow: 'none',
    borderRadius: 4,
    padding: '10px 0',
    '&:hover': {
      backgroundColor: '#FF551F!important',
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: '#FF551F!important',
      boxShadow: 'none',
    },
  },
  label: {
    fontWeight: 700,
    fontSize: 18,
    textTransform: 'none',
  },
})(Button);
