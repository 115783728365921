import { AppTheme, colors } from '@coverright/ui/themes';
import { Box, Card, ScopedCssBaseline, ThemeProvider } from '@mui/material';
import { ApolloProvider } from '@apollo/client';
import * as React from 'react';
import './helpers.css';
import { AppStateContext, IMedicareType, withAppStateContextProvider } from '../shared/AppStateContext';
import SelectPlanType from '../components/SelectPlanType';
import MedicarePlanList from '../components/MedicarePlanList';
import MedigapPlanList from '../components/MedigapPlanList';
import MedigapFilters from '../components/MedigapFilters';
import Home from '../components/Home';
import { client } from '@coverright/data-access/apollo-clients';
import { appPropsHandler } from '@coverright/utils';
import { useLogEvent } from '@coverright/shared/analytics';



export function App(props: any) {
  React.useEffect(() => {
    appPropsHandler(props);
  }, [props])

  return (
    <ThemeProvider theme={AppTheme}>
      <ScopedCssBaseline style={{backgroundColor: 'unset'}}>
        <Layout />
      </ScopedCssBaseline>
    </ThemeProvider>
  );
}

export default App;

const Layout = withAppStateContextProvider(() => {
  const [{currentStep, medicareType}] = React.useContext(AppStateContext);
  const logEvent = useLogEvent();

  React.useEffect(() => {
    let page_title: string;
    switch (currentStep) {
      case 1: page_title = 'SelectPlanType'; break;
      case 2: page_title = medicareType === IMedicareType.MG ? 'MedigapFilters' : 'MedicarePlanList'; break;
      default: page_title = 'Home';
    }
    logEvent('widget_screen_view', {
      page_title,
      page: window.parent.location.href,
      page_canonical: window.parent.location.origin + window.parent.location.pathname
    })
  }, [currentStep])

  return <ApolloProvider client={client}>
    <Box p={'5px'}>
      <Card sx={{
        boxShadow: '0 -1px 2px rgb(24 28 71 / 4%), 0 2px 4px rgb(24 28 71 / 10%)',
        p: '24px 22px 16px 22px',
        borderLeft: `11px solid ${colors.custom.green.variant2}`,
        borderTop: `1px solid #d7def0`,
        borderRight: `1px solid #d7def0`,
        borderBottom: `1px solid #d7def0`,

      }}>
        {currentStep === 0 && <Home />}
        {currentStep === 1 && <SelectPlanType />}
        {currentStep === 2 && <>
          {medicareType === IMedicareType.MG && <MedigapFilters />}
          {medicareType === IMedicareType.MA && <MedicarePlanList />}
        </>}
        {currentStep === 3 && <MedigapPlanList />}
      </Card>
    </Box>
  </ApolloProvider>;
})
