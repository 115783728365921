import { PlanFilterContext } from '../marketplace/PlanFilterContext';
import { colors } from '@coverright/ui/themes';
import { CRInfo, CRModal} from '@coverright/ui/shared';
import * as React from 'react';
import { Box, Button, Card, Divider, IconButton, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/material';
import CategoryTable, { OMVal } from './category-table';
import {
  getNumbersFromString,
  toCurrency
} from '@coverright/utils';
import CategorySelector from './category-selector';
import { MedigapQuoteContext } from '@coverright/shared/contexts';
import { IMedigapPlanDetails, MedigapPlanData, useMedigapPremiumRanges } from '@coverright/data-access/medigap';
import { MedigapPlanName } from '@coverright/data-access/types/medigap';
import { getDeductible } from '@coverright/shared/config';
import { useLogEvent } from '@coverright/shared/analytics';

export function MGCategory(props: {onClose: () => void, open: boolean }) {
  const [category, setCategory] = React.useState(PlanCategory.MostPopular);
  const [getData, {data}] = useMedigapPremiumRanges();
  const {medigapFilterState} = React.useContext(MedigapQuoteContext);
  const {values: filterValues, switchCategory} = React.useContext(PlanFilterContext);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  const logEvent = useLogEvent();

  React.useEffect(() => {
    logEvent('Page view - MG categories')
  }, [props.open]);

  React.useEffect(() => {
    if (filterValues?.selectedPlanCategory && filterValues?.selectedPlanCategory !== category && filterValues?.selectedPlanCategory !== 'all') {
      setCategory(filterValues?.selectedPlanCategory as any);
    }
  }, [filterValues?.selectedPlanCategory]);

  React.useEffect(() => {
    if (filterValues?.zip) {
      getData({variables: {
        zip: filterValues.zip,
        age: filterValues.age,
        tobacco: filterValues.tobacco,
        gender: filterValues.gender as any,
      }});
    }
  }, [filterValues]);

  const goToPlans = () => {
    if (filterValues?.selectedPlanCategory !== category) {
      switchCategory(category);
    }
    props.onClose();
  };

  const plans = React.useMemo(() => {
      return planCategories
        .find(d => d.name === category)?.plans
        .map(name => MedigapPlanData[name])
        .map(plan => ({...plan, premium: roundPremiums(data?.medigapPremiumRanges?.find(r => r.planName === plan.name)?.rangeDescription) || ''}))
        .map(plan => ({...plan, outOfPocketLimits: calculateOutOfPocket(plan)}))
  }, [category, data, medigapFilterState?.age]);

  return (
    <CRModal
      width={952}
      open={props.open}
      onClose={() => props.onClose()}
      paperPadding={{xs: '24px 0px 0', md: '25px 25px 0 45px'}}
    >
      <>
        <Box data-test={'mg_compare_modal'} sx={{position: 'sticky', left: 0, px: {xs: '10px', md: 0}}}>
          <IconButton onClick={props.onClose} sx={{position: "absolute", top: -19, right: {xs: 8, md: -15},}}><img src={'/assets/img/close.svg'} /></IconButton>
          <Typography className={'bold fs-26 lh-32 mb-16'}>Compare Medicare Supplement plans</Typography>
          <CRInfo open={true} wrapperSxProps={{maxWidth: 700}} description={<>Medicare Supplement plans are federally standardized.  Plan G from ‘Company A’ provides the same exact coverage as Plan G from ‘Company B’. <strong>Once you have selected a specific plan type, the only difference across insurance companies is price</strong>.</>} title={'How do I compare Medicare Supplement plans?'}/>
          <CategorySelector selectedCategory={category} onClick={setCategory} />
          <div className={'h-25'} />
          <Card sx={{overflow: 'unset'}}>
            <Box sx={{backgroundColor: colors.text.primary, display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '12px 16px', borderRadius: '10px 10px 0 0'}}>
              <Typography data-test={'mg_compare_modal_category_name'} sx={{color: 'white'}} className={'fs-20 lh-25 medium p-5'} align={'center'}>Category: <strong>{category}</strong></Typography>
              {!phone && <Button data-test={'mg_compare_modal_shop_category_button'} onClick={goToPlans} sx={{px: 6}} variant={'contained'}>Shop {category} plans</Button>}
            </Box>
            <Box sx={{mb: {xs: '15px', md: 0}}}>
              <div className={'pr-10'}>
                {getLabelForCategory({
                  cat: category,
                  sx: {
                    '& ul': {
                      '& li': {
                        fontSize: 14,
                        lineHeight: '20px',
                        pb: '3px',
                      }
                    }
                  },
                  skipTitle: true,
                  age: medigapFilterState?.age
                })}
              </div>
              {phone && <Button sx={{mx: 1, width: 'calc(100% - 16px)'}} onClick={goToPlans} variant={'contained'}>Shop {category} plans</Button>}
            </Box>
          </Card>
        </Box>
        <CategoryTable plans={plans} />
        <div className={'h-40'} />
      </>
    </CRModal>
  );
}

export default MGCategory;

const calculateOutOfPocket = (plan: IMedigapPlanDetails) => {
  const addValue = (input?: string, value?: string): string => {
    if (!input) {
      return ''
    }
    if (!value) {
      return input;
    }

    const numbers = input.split(' - ').map(v => getNumbersFromString(v)[0]);
    if (numbers.length > 1 && numbers[0] === numbers[1]) {
      numbers.splice(1);
    }
    const val = getNumbersFromString(value)[0];

    return numbers.map(v => v * 12 + val).map(v => toCurrency(v)).join(' - ')
  }

  switch (plan.name) {
    case MedigapPlanName.Hdg:
    case MedigapPlanName.Hdf:return <OMVal value={addValue(plan.premium || '', '2700') || 'Not available'}
                                           subtitleClassName={'mt-20'}
                                           subtitle={<span>Your annual out-of-pocket costs in a catastrophic scenario is equal to:<br /><br />+ Total annual premium<br />+ Deductible</span>}/>;

    case MedigapPlanName.G: return <OMVal value={addValue(plan.premium || '', typeof plan.partBDeductible === 'string' ? plan.partBDeductible : '0') || 'Not available'}
                                          subtitleClassName={'mt-20'}
                                          subtitle={<span>Your annual out-of-pocket costs in a catastrophic scenario is equal to:<br /><br />+ Total annual premium<br />+ Deductible</span>}/>;

    case MedigapPlanName.K: return <OMVal value={addValue(plan.premium || '', '6220') || 'Not available'}
                                          subtitleClassName={'mt-20'}
                                          subtitle={<span>Your annual out-of-pocket costs in a catastrophic scenario is equal to:<br /><br />+ Total annual premium<br />+ Plan maximum out-of-pocket limit: $6,220</span>}/>;

    case MedigapPlanName.L: return <OMVal value={addValue(plan.premium || '', '3110') || 'Not available'}
                                          subtitleClassName={'mt-20'}
                                          subtitle={<span>Your annual out-of-pocket costs in a catastrophic scenario is equal to:<br /><br />+ Total annual premium<br />+ Plan maximum out-of-pocket limit: $3,110</span>}/>;

    case MedigapPlanName.M: {
      const str = addValue(plan.premium || '', typeof plan.partBDeductible === 'string' ? plan.partBDeductible : '0');
      const value = str ? <span className={'relative'}>{str} <Box sx={{position: 'absolute', left: 0, top: 19}} className={'no-wrap fs-11 lh-12 regular'}>plus costs not covered</Box></span> : 'Not available';
      return <OMVal value={value}
             subtitleClassName={'mt-20'}
             subtitle={<span>Your annual out-of-pocket costs in a catastrophic scenario is equal to:
                                            <br/><br/>+ Total annual premium
                                            <br/>+ Deductible
                                            <Divider
                                              sx={{ mt: '10px', mb: '5px', mr: '30px', borderColor: '#7B8F95' }}/>
                                            <span className={'fs-11 lh-12'}>Plus costs not covered:</span>
                                            <br/>+ Any Part B excess charges
                                            <br/>+ Any costs from short-term hospital stays
                                          </span>}/>
  };

    case MedigapPlanName.N: {
      const str = addValue(plan.premium || '', typeof plan.partBDeductible === 'string' ? plan.partBDeductible : '0') || 'Not available';
      const value = str ? <span className={'relative'}>{str} <Box sx={{position: 'absolute', left: 0, top: 19}} className={'no-wrap fs-11 lh-12 regular'}>plus costs not covered</Box></span> : 'Not available';

      return <OMVal value={value}
                    subtitleClassName={'mt-20'}
                    subtitle={<span>Your annual out-of-pocket costs in a catastrophic scenario is equal to:
                                            <br /><br />+ Total annual premium
                                            <br />+ Deductible
                                            <Divider sx={{mt: '10px', mb: '5px', mr: '30px', borderColor: '#7B8F95'}} />
                                            <span className={'fs-11 lh-12'}>Plus costs not covered:</span>
                                            <br />+ Any Part B excess charges
                                            <br />+ Any doctor/emergency visits
                                          </span>}/>
    };

    case MedigapPlanName.D: {
      const str = addValue(plan.premium || '', typeof plan.partBDeductible === 'string' ? plan.partBDeductible : '0') || 'Not available';
      const value = str ? <span className={'relative'}>{str} <Box sx={{position: 'absolute', left: 0, top: 19}} className={'no-wrap fs-11 lh-12 regular'}>plus costs not covered</Box></span> : 'Not available';
      return <OMVal value={value}
                    subtitleClassName={'mt-20'}
                    subtitle={<span>Your annual out-of-pocket costs in a catastrophic scenario is equal to:
                      <br /><br />+ Total annual premium
                      <br />+ Deductible
                      <Divider sx={{mt: '10px', mb: '5px', mr: '30px', borderColor: '#7B8F95'}} />
                      <span className={'fs-11 lh-12'}>Plus costs not covered:</span>
                      <br />+ Any Part B excess charges
                    </span>}/>
    };


    case MedigapPlanName.C: {
      const str = addValue(plan.premium || '', '0') || 'Not available';
      const value = str ? <span className={'relative'}>{str} <Box sx={{position: 'absolute', left: 0, top: 19}} className={'no-wrap fs-11 lh-12 regular'}>plus costs not covered</Box></span> : 'Not available';
      return <OMVal value={value}
                    subtitleClassName={'mt-20'}
                    subtitle={<span>Your annual out-of-pocket costs in a catastrophic scenario is equal to:
                                            <br /><br />+ Total annual premium
                                            <Divider sx={{mt: '10px', mb: '5px', mr: '30px', borderColor: '#7B8F95'}} />
                                            <span className={'fs-11 lh-12'}>Plus costs not covered:</span>
                                            <br />+ Any Part B excess charges
                                          </span>}/>
    };

    case MedigapPlanName.A:
    case MedigapPlanName.B: return <OMVal value={'Not applicable'}
                                          subtitleClassName={'mt-20'}
                                          subtitle={<span>Your annual out-of-pocket costs in a catastrophic scenario is not capped.</span>}/>;

    default: return <OMVal value={addValue(plan.premium || '', '0') || 'Not available'}
                           subtitleClassName={'mt-20'}
                           subtitle={<span>Your annual out-of-pocket costs in a catastrophic scenario is equal to:<br /><br />+ Total annual premium</span>}/>
  }
}

const roundPremiums = (input?: string) => {
  if (!input) {
    return ''
  }
  const numbers = input.split(' - ').map(v => getNumbersFromString(v)[0]);
  if (numbers.length > 1 && numbers[0] === numbers[1]) {
    numbers.splice(1);
  }
  return numbers.map(Math.round).map(v => toCurrency(v)).join(' - ')
}



export enum PlanCategory {
  MostPopular = 'Most Popular',
  CoreBenefits = 'Core benefits',
  Comprehensive = 'Comprehensive',
  CostShare = 'Cost-share',
  HighDeductible = 'High deductible',
}

export type PlanCategoryDescription = {
  name: PlanCategory,
  plans: MedigapPlanName[]
}

export const planCategories: PlanCategoryDescription[] = [
  {name: PlanCategory.MostPopular, plans: [MedigapPlanName.F, MedigapPlanName.G, MedigapPlanName.N]},
//  {name: PlanCategory.Comprehensive, plans: [MedigapPlanName.C, MedigapPlanName.D, MedigapPlanName.F, MedigapPlanName.G]},
  {name: PlanCategory.HighDeductible, plans: [MedigapPlanName.Hdf, MedigapPlanName.Hdg]},
//  {name: PlanCategory.CostShare, plans: [MedigapPlanName.K, MedigapPlanName.L, MedigapPlanName.M, MedigapPlanName.N]},
  {name: PlanCategory.CoreBenefits, plans: [MedigapPlanName.A, MedigapPlanName.B]},
];

type LabelForCategoryProps = {
  cat: PlanCategory,
  sx?: SxProps,
  skipTitle?: boolean,
  titleClassName?: string,
  showShort?: boolean,
  age?: number
}

export const getLabelForCategory = (props: LabelForCategoryProps) => {
  const {cat, sx, skipTitle, titleClassName, showShort, age} = props;
  switch (cat) {
    case PlanCategory.MostPopular: return <>
      {!skipTitle && <Typography className={titleClassName || 'fs-12 bold'}>Plan F, G and N</Typography>}
      {showShort && <Typography className={'fs-14'} sx={{letterSpacing: '-1px'}}>Selected by over <strong className={'dark-green'}>80%</strong> of all Medicare Supplement enrollees.</Typography>}
      <Box sx={sx}>
        <ul>
          <li>Selected by <strong className={'dark-green'}>80%</strong> of all Medicare Supplement enrollees.  These plans also represent the plans we recommend most to customers purchasing Medicare Supplement</li>
          <li>You pay a higher monthly premium in exchange for minimal out-of-pocket costs</li>
          <li>Most beneficiaries will select a plan in this category based on monthly premium budget</li>
          <li>Plan G is now most popular plan for those new to Medicare as Plan F is no longer available to those who became newly eligible for Medicare after Jan 1, 2020</li>
        </ul>
      </Box>
    </>;
    case PlanCategory.CoreBenefits: return <>
      {!skipTitle && <Typography color={'textPrimary'} className={titleClassName || 'fs-12 bold'}>Plan A and B</Typography>}
      {showShort && <Typography className={'fs-14'} sx={{letterSpacing: '-1px'}}>Selected by <strong className={'dark-green'}>3%</strong> of all Medicare Supplement enrollees.</Typography>}
      <Box sx={sx}>
        <ul>
          <li>Selected by <strong className={'dark-green'}>3%</strong> of all Medicare Supplement enrollees.  In general, we recommend consumers looking at Plan A/B to review Medicare Advantage options instead</li>
          <li>These plans include basic benefits such as coverage for your 20% out-of-pocket for your medical services (also known as the Part B coinsurance)</li>
          <li>Plan B includes coverage for the short-term hospital stay deductible of {getDeductible('inpatientHospitalDeductible')} which covers first 60 days of stay (also known as the Part A deductible)</li>
          <li>You may sometimes find that a plan with less coverage may cost similar or more than a plan with more coverage.  This can sometimes occur because the plan with less coverage is less popular and carriers may need to charge more to cover the costs of paying claims (while more popular plans can average out claim costs across a larger population).</li>
        </ul>
      </Box>
    </>;
    case PlanCategory.Comprehensive: return <>
      {!skipTitle && <Typography color={'textPrimary'} className={titleClassName || 'fs-12 bold'}>Plan C, D, F and G</Typography>}
      {showShort && <Typography className={'fs-14'} sx={{letterSpacing: '-1px'}}>Selected by over <strong className={'dark-green'}>77%</strong> of all Medicare Supplement enrollees.</Typography>}
      <Box sx={sx}>
        <ul>
          <li>Selected by <strong className={'dark-green'}>77%</strong> of all Medicare Supplement enrollees (primarily Plan F and G)</li>
          <li>These plans provide medium-to-high coverage for out-of-pocket costs</li>
          <li>Plan C and F are no longer available to those who became eligible for Medicare after Jan 1, 2020</li>
          <li>You may sometimes find that a plan with less coverage may cost similar or more than a plan with more coverage.  This can sometimes occur because the plan with less coverage is less popular and carriers may need to charge more to cover the costs of paying claims (while more popular plans can average out claim costs across a larger population).</li>
        </ul>
      </Box>
    </>;
    case PlanCategory.CostShare: return <>
      {!skipTitle && <Typography color={'textPrimary'} className={titleClassName || 'fs-12 bold'}>Plan K, L, M and N</Typography>}
      {showShort && <Typography className={'fs-14'} sx={{letterSpacing: '-1px'}}>Selected by over <strong className={'dark-green'}>12%</strong> of all Medicare Supplement enrollees.</Typography>}
      <Box sx={sx}>
        <ul>
          <li>Selected by <strong className={'dark-green'}>12%</strong> of all Medicare Supplement enrollees (primarily Plan N). Other than Plan N, we would typically recommend our customers look at High Deductible plans which we think are better value than cost-share plans (K, L and M)</li>
          <li>For Plans K, L and M, you pay 25-50% of out-of-pocket obligations. Once an annual out-of-pocket limit is reached, the plan pays 100% of costs</li>
          <li>For Plan N, you get relatively comprehensive coverage with little cost-share. You pay no coinsurance except copays for doctor visits and emergency room visits</li>
          <li>You may sometimes find that a plan with less coverage may cost similar or more than a plan with more coverage.  This can sometimes occur because the plan with less coverage is less popular and carriers may need to charge more to cover the costs of paying claims (while more popular plans can average out claim costs across a larger population).</li>
        </ul>
      </Box>
    </>;
    case PlanCategory.HighDeductible: return <>
      {!skipTitle && <Typography color={'textPrimary'} className={titleClassName || 'fs-12 bold'}>Plan F and G (high deductible)</Typography>}
      {showShort && <Typography className={'fs-14'} sx={{letterSpacing: '-1px'}}>High deductible versions of the two most popular plans (Plan F, G)</Typography>}
      <Box sx={sx}>
        <ul>
          <li>High deductible versions of the two most popular plans (Plan F and G)</li>
          <li>You pay a deductible of {getDeductible('deductible')} before the plan begins to pay</li>
          <li>Once the deductible is met, the plan covers 100% of the costs like a regular Plan F and G</li>
          <li>These plans are a good option if you want network flexibility as well as catastrophic coverage and do not expect to use healthcare services often</li>
          <li>Plan F is no longer available to those who became newly eligible for Medicare after Jan 1, 2020</li>
        </ul>
      </Box>
    </>;
    default: return <></>;
  }
}

