import React from "react";
import {AppStateActionTypes, AppStateContext, IMedicareType} from "../shared/AppStateContext";
import { createStyles, makeStyles } from '@mui/styles';
import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import BackButton from '../shared/BackButton';
import { environment } from '../environments/environment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      width: 125,
      height: 24,
      maxWidth: 125,
      maxHeight: 24,
    }
  }),
);


const SelectPlanType = () => {
  const classes = useStyles();
  const [{currentStep}, dispatch] = React.useContext(AppStateContext);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('sm'));
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const onClick = (medicareType: IMedicareType) => {
    dispatch({type: AppStateActionTypes.UPDATE, payload: {medicareType, currentStep: 2}})
  }

  return <div className={!phone ? 'crw-flex' : ''}>
    <div className={`crw-flex-column ${!phone ? 'crw-mr-55 crw-flex-space-between' : 'crw-mb-20'}`}>
      <div>
        <Typography className={'crw-dark-green crw-fs-20 crw-medium'}>Select your plan type:</Typography>
        <Typography className={'crw-bold crw-fs-18'} color={'textPrimary'}>Choose the type of plan you are {desktop ? <br /> : ''}looking to get quotes for</Typography>
      </div>
      <div>
        <BackButton
          startIcon={<img src={environment.imagesUrl + "/img/arrow-left.svg"}/>}
          onClick={() => dispatch({type: AppStateActionTypes.BACK})}>
          Back
        </BackButton>
      </div>
    </div>

    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <StyledButton label={'Medicare Supplement (Medigap)'} img={environment.imagesUrl + '/img/Hand.svg'} onClick={() => onClick(IMedicareType.MG)}/>
    </Box>
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'} className={!phone ? 'crw-w-85' : 'crw-mv-15'}>
      <Typography color={'textPrimary'} align={'center'} className={'crw-fs-20 crw-bold'}>OR</Typography>
    </Box>
    <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
      <StyledButton label={<>Medicare<br />Advantage</>} img={environment.imagesUrl + '/img/Building.svg'} onClick={() => onClick(IMedicareType.MA)}/>
    </Box>
  </div>;
}

export default SelectPlanType;

const StyledButton = ({label, img, onClick}: {label: string | JSX.Element, img: string, onClick: () => void}) => {
  return <Box sx={{
    backgroundColor: '#EFFAF5',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    width: 145,
    minWidth: 145,
    height: 145,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    cursor: 'pointer',
  }} onClick={onClick}>
      <Typography color={'textPrimary'} align={'center'} className={'crw-fs-14 crw-bold'}>{label}</Typography>
      <img src={img} width={60} />
  </Box>
}

