import React, {useEffect} from "react";
import * as _ from "lodash";
import {AppStateActionTypes, AppStateContext} from "../shared/AppStateContext";
import { Box, Button, MenuItem,  Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { makeStyles, createStyles, withStyles } from '@mui/styles';
import { colors } from '@coverright/ui/themes';
import { CRPreloader } from '@coverright/ui/shared';
import { SelectInput } from '@coverright/ui/inputs';
import ZipInput from '../shared/ZipInput';
import BackButton from '../shared/BackButton';
import { getUTMParams, useDebouncedEffect } from '@coverright/utils';
import { environment } from '../environments/environment';
import PlanCard from '../../../../mg-marketplace/src/app/marketplace/components/PlanCard';
import { Gender, MedigapPlanName, MedigapPlansField, Sort } from '@coverright/data-access/types/medigap';
import { addDetailsToMGPlanList, MedigapPlanOutputWithDetails, useMedigapPlans } from '@coverright/data-access/medigap';
import { useLogEvent } from '@coverright/shared/analytics';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 500,
    },
    card: {
      padding: '24px 30px',
      maxWidth: 1000
    },
    disclosure: {
      fontSize: 20,
      fontWeight: 500,
      marginRight: 8,
      marginTop: 1,
    },
    underline: {
      borderBottom: '1px dashed #1C434F',
      cursor: 'pointer'
    },
    county: {
      cursor: 'pointer'
    },
    seeMoreButton: {
      '& .MuiButton-label': {
        textTransform: 'none',
        color: colors.custom.green.variant2
      }
    },
    hugeText: {
      fontSize: 32,
      fontWeight: 700,
    },
    bold: {
      fontWeight: 700,
    },
    thin: {
      fontWeight: 400,
    },
    select: {
      '& .MuiOutlinedInput-input': {
        fontSize: '12px',
        fontWeight: 500,
        padding: '12px 15px 10px!important',
        zIndex: 1,
        lineHeight: 'normal',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        marginTop: 4,
        height: 36,
      },
    },
    paper: {
      '& li.MuiListItem-root': {
        fontSize: 12,
      }
    },
    logo: {
      width: 125,
      height: 24,
      maxWidth: 125,
      maxHeight: 24,
    }
  }),
);

interface PlansDictionary {
  [type: string]: MedigapPlanOutputWithDetails[]
}

function MedigapPlanList() {
  const classes = useStyles();
  const [appState, dispatch] = React.useContext(AppStateContext);
  const [plans, setPlans] = React.useState<PlansDictionary>({});
  const [request, results] = useMedigapPlans();
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  const [age, setAge] = React.useState(65);
  const [gender, setGender] = React.useState(Gender.M);
  const [filter, setFilter] = React.useState<any>({});
  const logEvent = useLogEvent();

  React.useEffect(() => {
      if (appState.age) {
        setAge(appState.age)
      }
      if (appState.gender) {
        setGender(appState.gender)
      }
      if (appState.zip) {
        setFilter({zip: appState.zip, county: appState.county})
      }
  }, [appState]);


  useDebouncedEffect(() => {
    if (!!age && !!gender && !!filter.zip && !!filter.county)
    request({
      variables: {
        filter: {
          zip: filter.zip || '',
          county: filter.county || '',
          age: age || 65,
          gender: gender || Gender.M,
          tobacco: false,
          monthlyPlanPremiumRanges: [],
          companies: [],
          planNames: [MedigapPlanName.N, MedigapPlanName.G, MedigapPlanName.F],
        },
        page: {
          page: 0,
          size: 1000
        },
        sort: [{
          direction: Sort.Asc,
          field: MedigapPlansField.MonthlyPremium
        }]
      }
    })
  }, 300, [age, gender, filter]);


  React.useEffect( () => {
    if(results.data) {
      setPlans(_.groupBy(addDetailsToMGPlanList(results.data.medigapPlans.data), 'planName'));
    }
  }, [results.data]);

  const onPlanClick = (utm: string) => {
    logEvent('click', {
      element_title: 'See all plans now',
      element_id: utm === 'mg_all_plans' ? 'partners-widget-mg-all-plans' : 'partners-widget-mg-single-plan',
      page: window.parent.location.href,
      page_canonical: window.parent.location.origin + window.parent.location.pathname
    }).then(() => {
      const host = window.parent.document.location.host;
      const isForbes = host === 'forbes.com' || host === 'www.forbes.com';

      const url = new URL(environment.dashboardUrl + (isForbes ? 'forbes/basic-info' : 'application-onboarding/zip'))

      const filters = {...filter, age, gender, tobacco: false, product: 'short-medigap', dashboard: 'z1' };

      Object.entries(filters).forEach(([key, value]) => {
        url.searchParams.set(key, value as string);
      })

      if (isForbes) {
        url.searchParams.set('mp', 'mg')
      }

      window.open(url.toString() + getUTMParams(utm));
    })

  }

  return <>
    <Box flexDirection={'column'} alignItems={'center'}>
      <BackButton
        startIcon={<img src={environment.imagesUrl + "/img/arrow-left.svg"}/>}
        onClick={() => dispatch({type: AppStateActionTypes.BACK})}>
        Back
      </BackButton>
      <Typography color={'textPrimary'} className={'crw-medium crw-fs-22'}>Shop Medicare Supplement plans, 100% online.</Typography>
      <Typography color={'textSecondary'} className={'crw-fs-18 crw-mb-12'}>Visit CoverRight to see instant personalized quotes</Typography>
      <SeePlansButton variant={'rounded'}
                      sx={{fontSize: 18}}
                      id={'partners-widget-mg-all-plans'}
                      onClick={() => onPlanClick('mg_all_plans')}
                      fullWidth>
        See all plans now
      </SeePlansButton>

      <div className={`crw-flex crw-mb-16 crw-mt-12 ${phone ? 'crw-flex-column' : ''}`}>
        <div className={'crw-flex crw-flex-align-center crw-mr-20'}>
          <Typography color={'textPrimary'} className={`crw-medium crw-fs-14 crw-mr-10 ${phone ? 'crw-w-50 ' : ''}`}>Zip</Typography>
          <ZipInput className={'crw-mb-0'} label={''} onChange={values => setFilter(values)} zip={appState.zip} county={appState.county}  />
        </div>
        <div className={`crw-flex crw-flex-align-center crw-mr-20  ${phone ? 'crw-mv-10 ' : ''}`}>
          <Typography sx={{mt: '1px'}} color={'textPrimary'} className={`crw-medium crw-fs-14 crw-mr-10 ${phone ? 'crw-w-50 ' : ''}`}>Age</Typography>
          <SelectInput label={''}
                       hideTick
                       sx={{'& .MuiSelect-iconOutlined': {marginTop: '2px'}, '& div.MuiOutlinedInput-input': {m: '5px 2px 2px!important', p: '4px 15px 2px!important'}}}
                       className={`crw-mb-0 crw-w-105 ${classes.select}`}
                       paperClassName={classes.paper}
                       placeholder={'Select your age'}
                       value={age}
                       onChange={(event) => {
                         setAge(event.target.value as number)
                       }}
          >
            <MenuItem value={64}>64-</MenuItem>
            {Array(50).fill(2).map((p, i) => <MenuItem key={i} value={i + 65}>{i + 65}</MenuItem>)}
          </SelectInput>
        </div>
        <div className={'crw-flex crw-flex-align-center'}>
          <Typography sx={{mt: '1px'}} color={'textPrimary'} className={'crw-medium crw-fs-14 crw-mr-10'}>Gender</Typography>
          <SelectInput label={''}
                       hideTick
                       sx={{'& .MuiSelect-iconOutlined': {marginTop: '2px'}, '& div.MuiOutlinedInput-input': {m: '5px 2px 1px!important', p: '4px 15px 2px!important'}}}
                       className={`crw-mb-0 crw-w-105 ${classes.select}`}
                       paperClassName={classes.paper}
                       placeholder={'Select your gender'}
                       value={gender}
                       onChange={(event) => {
                         setGender(event.target.value as Gender)
                       }}
          >
            <MenuItem value={'male'}>Male</MenuItem>
            <MenuItem value={'female'}>Female</MenuItem>
          </SelectInput>
        </div>
      </div>

      <Box mt={'16px'}>

        {!results.loading && Object.keys(plans).map(key => <PlanCard zip={appState.zip || ''}
                                                                     open={false}
                                                                     imgPrefix={environment.imagesUrl.replace('/assets', '')}
                                                                     countyName={appState.county || ''}
                                                                     onExpandedClick={() => onPlanClick('mg_single_plan')}
                                                                     plans={plans[key]} key={key} />)}
        {results.loading &&
          <Box textAlign={"center"} my={2}>
            <CRPreloader />
          </Box>
        }
      </Box>
      <div className={`crw-flex crw-flex-align-center crw-flex-space-between crw-mt-12 ${phone ? 'crw-flex-column' : ''}`}>
        <div className={`crw-flex crw-flex-align-center ${phone ? 'crw-mv-15' : ''}`}>
          <Typography color={'textPrimary'} className={'crw-fs-10 crw-pointer'}
                      onClick={() => window.open('https://coverright.com/coverright-terms-of-use/')}>Terms of Service</Typography>
          <Typography color={'textPrimary'} className={'crw-fs-10 crw-mh-8'}>|</Typography>
          <Typography color={'textPrimary'} className={'crw-fs-10 crw-pointer'}
                      onClick={() => window.open('https://coverright.com/privacy/')}>Privacy Policy</Typography>
        </div>
        <img src={environment.imagesUrl + '/img/Logo.svg'} className={classes.logo} />
      </div>
    </Box>
  </>
}

export default MedigapPlanList;


const SeePlansButton = withStyles({
  rounded: {
    color: 'white',
    backgroundColor: '#FF785A!important',
    boxShadow: 'none',
    borderRadius: 4,
    padding: '10px!important',
    '&:hover': {
      backgroundColor: '#FF551F!important',
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: '#FF551F!important',
      boxShadow: 'none',
    },
  },
  label: {
    fontSize: 18,
    lineHeight: '15px',
    textTransform: 'none',
    fontWeight: 700,
  },
})(Button)
