import React from "react";
import { Box, IconButton, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/material';
import { colors } from '@coverright/ui/themes';
import { Tooltip } from '@coverright/ui/inputs';
import { isPersonEligible } from '@coverright/utils';
import {tooltips} from '@coverright/utils';
import { MedigapQuoteContext } from '@coverright/shared/contexts';
import { MedigapPlanName } from '@coverright/data-access/types/medigap';
import { IMedigapPlanDetails } from '@coverright/data-access/medigap';
import { PlanFilterContext } from '../marketplace/PlanFilterContext';
import { getDeductible } from '@coverright/shared/config';

interface CategoryTableProps {
  plans?: IMedigapPlanDetails[],
}

const styles: (planCount: number) => SxProps = (planCount: number) => ({
  mt: '25px',
  px: 1,
  position: 'relative',
  '& table': {
    tableLayout: "fixed",
    width: 'calc(100% - 16px)',
    borderSpacing: 0,
    borderCollapse: 'collapse',
    letterSpacing: '-1px',
    '& tbody': {
      '& tr td': {
        width:  150,
        '&:first-child': {
          position: 'sticky',
          left: 0,
          zIndex: 1,
          background: 'white',
          width:  170,
        },
      },
      '& tr:not(.delimeter)': {
        '& td:first-child': {
          padding: '10px 16px',
          '&:after': {
            boxShadow: '10px 0px 10px -10px rgba(0, 0, 0, 0.15) inset',
            content: '" "',
            height: '100%',
            position: 'absolute',
            top: '0',
            right: '-15px',
            width: '15px',
          },
        },
        '& td:not(:first-child)': {
          padding: '10px 30px',
        },
      },
      '& tr:not(.subheader):not(.header) td': {
        verticalAlign: 'top',
        borderBottom: '1px solid #E8ECED'
      },
      '& tr:not(.delimeter):not(.header)': {
        '& td:first-child': {
          '&:before': {
            boxShadow: '-10px 0px 10px -10px rgba(0, 0, 0, 0.15) inset',
            content: '" "',
            height: '100%',
            top: '0',
            left: '-15px',
            position: 'absolute',
            width: '15px',
          },
        },
      },
      '& tr.header': {
        '& td': {
          background: colors.custom.green.variant2,
          color: 'white',
          fontSize: 20,
          fontWeight: 700,
          height: '33px',
        },
      },
      '& tr.subheader': {
        '& td': {
          background: '#F2F9F9',
          fontSize: 18,
          lineHeight: '22px',
          fontWeight: 500,
          height: '45px',
          color: colors.text.primary,
        },
      },
      '& tr.delimeter': {
        '& td': {
          height: '33px',
          '&:first-child': {
            boxShadow: '1px 7px 14px -10px rgb(0 0 0 / 15%) inset',
          },
        },
      }
    }
  }
})

const CategoryTable = (props: CategoryTableProps) => {
  const {values} = React.useContext(PlanFilterContext);
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));
  const [index, setIndex] = React.useState(0);

  const plans = React.useMemo(() => {
    if (!props.plans) {
      return undefined
    }
    return props.plans;
  }, [props.plans, phone, index]);

  const isEligible = React.useMemo(() => {
    return isPersonEligible(values?.age, values?.effectiveDate)
  }, [values?.age, values?.effectiveDate])

  if (!plans) {
    return null;
  }

  return <Box sx={styles(plans.length)}>
      <table>
        <tbody>
          <tr className={'header'}>
            <td></td>
            {plans.map(plan => <td>Plan {plan.name}</td>)}
          </tr>
          <tr>
            <td>
              <Tooltiped
                tooltip={'The % of Medicare Supplement enrollees who enroll in the specified plan.  This is based on the total number of enrollees in each plan as reported by America\'s Health Insurance Plans (AHIP) in 2019'}
                content={<Typography className={'fs-16 lh-20 medium'}>% of Medicare<br />Supplement enrollees</Typography>}
              />
            </td>
            {plans.map(plan => <td>{plan.popularity}</td>)}
          </tr>
          <tr>
            <td>
              <Tooltiped
                tooltip={'This is the fixed monthly amount charged by the insurance company to maintain coverage.  Plans with more limited or lower coverage typically also have a lower premium, while more comprehensive plans have a higher premium.  The premium varies depending on where you live, your age, gender and tobacco status.'}
                content={<Typography className={'fs-16 lh-20 medium'}>Monthly premium<div className={'fs-13 lh-16 regular'}>in addition to </div></Typography>}
              />
              <Tooltip title={tooltips.standardPartBPremium} arrow><Box component={'span'} sx={{ml: '18px'}} className={'dark-green pointer medium underline fs-14 lh-17'}>Standard Part B Premium</Box></Tooltip>
            </td>
            {plans.map(plan => <td>{isEligible ?
              plan.premium || 'Not available' :
              [MedigapPlanName.F, MedigapPlanName.C, MedigapPlanName.Hdf].includes(plan.name as MedigapPlanName) ? <>You are not <Tooltip title={'This plan is not available to Medicare beneficiaries who became eligible after Jan. 1, 2020'} arrow><span className={'dark-green underline '}>eligible</span></Tooltip><br />for this plan</> : plan.premium || 'Not available'}
            </td>)}
          </tr>
          <tr className={'delimeter'}>
            <td ></td>
            {plans.map(plan => <td></td>)}
          </tr>
          <tr className={'header'}>
            <td>Original Medicare</td>
            {plans.map(plan => <td>Plan {plan.name}</td>)}
          </tr>
          <tr className={'subheader'}>
            <td>
              Key out-of-pocket costs:
            </td>
            {plans.map(plan => <td></td>)}
          </tr>
          <tr>
            <td>
              <Tooltiped
                tooltip={'The amount you must pay out of pocket for your medical (Part B) costs before your plan coverage begins.'}
                content={<OMValue title={'Deductible:'} value={getDeductible('annualPartBDeductible')} subtitle={'Medical deductible'} />}
              />
            </td>
            {plans.map(plan => <td>{plan.deductible}</td>)}
          </tr>
          <tr>
            <td>
              <Tooltiped
                tooltip={'This the out-of-pocket costs you need to pay for medical services typically received at a doctor\'s office or other outpatient services (such as diagnostic scans, emergency room and/or ambulance services).   Medicare refers to this as the Part B (medical) coinsurance.'}
                content={<OMValue title={'Doctor visits / outpatient services:'} value={'20% coinsurance'} />}
              />
            </td>
            {plans.map(plan => <td><Box sx={{mt: {xs: '38px', sm: 0}}}>{plan.doctorAndOutpatientServices}</Box></td>)}
          </tr>
          <tr>
            <td>
              <Tooltiped
                tooltip={'This is the amount you must pay out of pocket under Original Medicare for your hospital (Part A) costs before your plan coverage begins.  This amount covers your first 60 days regardless of how long your hospital stay is.  Medicare refers to this as the Part A (hospital) deductible.'}
                content={<OMValue title={'Short-term hospital stay:'} value={getDeductible('inpatientHospitalDeductible') + ' copay'} subtitle={'covers first 60 days'} />}
              />
            </td>
            {plans.map(plan => <td>{plan.shortTermHospitalDay}</td>)}
          </tr>
          <tr>
            <td>
              <Tooltiped
                tooltip={'This is the amount you must pay out of pocket for an extended hospital stay (>60 days)'}
                content={<OMValue title={'Long-term hospital stay:'} value={getDeductible('dailyCoinsurance61_90') + ' per day'} subtitle={'For days 61-90'} />}
              />
            </td>
            {plans.map(plan => <td>{plan.longTermHospitalDay}</td>)}
          </tr>
          <tr>
            <td>
              <Tooltiped
                tooltip={'The maximum out-of-pocket costs that you can expect to pay for your healthcare services in each year.  This includes copays, coinsurance amounts for doctor/hospital visits and other covered healthcare services. '}
                content={<OMValue title={'Maximum Out-of-pocket:'} value={'Unlimited'} subtitleClassName={'mt-20'} subtitle={<span>Your out-of-pocket costs in a<br />catastrophic scenario is not capped.</span>} />}
              />
            </td>
            {plans.map(plan => <td>{plan.outOfPocketLimits}</td>)}
          </tr>
          <tr className={'delimeter'}>
            <td ></td>
            {plans.map(plan => <td></td>)}
          </tr>
          <tr className={'header'}>
            <td>Original Medicare</td>
            {plans.map(plan => <td>Plan {plan.name}</td>)}
          </tr>
          <tr className={'subheader'}>
            <td>
              Other coverage:
            </td>
            {plans.map(plan => <td></td>)}
          </tr>
          <tr>
            <td>
              <Tooltiped
                tooltip={'This refers to the amount a doctor can charge over the Medicare-approved amount.  Providers may charge up to 15% over the standard Medicare rates.'}
                content={<OMValue title={'Part B excess charges:'} value={'Not covered'}/>}
              />
            </td>
            {plans.map(plan => <td>{typeof plan.partBExcessCharges === 'boolean' ?  (plan.partBExcessCharges ? <OMVal value={'Covered'} /> : <OMVal value={'Not covered'} />) : plan.partBExcessCharges}</td>)}
          </tr>
          <tr>
            <td>
              <Tooltiped
                tooltip={'Skilled nursing facilities are Medicare-approved facilities that provide short-term extended care services following a hospital stay, at a lower level of care than provided in a hospital.'}
                content={<div>
                  <OMValue title={'Skilled nursing facilities:'} value={'$0 copay'} subtitle={'Days 1 - 20'} />
                  <OMVal value={getDeductible('skilledNursingFacility') + ' per day'} subtitle={'Days 21 - 100'} />
                </div>}
              />
            </td>
            {plans.map(plan => <td>{plan.skilledNursingFacility}</td>)}
          </tr>
          <tr>
            <td>
              <Tooltiped
                tooltip={'Some Medigap plans cover foreign travel emergency care if the emergency begins during the first 60 days of your trip, and if Medicare doesn\'t otherwise cover foreign emergency care.'}
                content={<OMValue title={'Foreign travel emergency'} value={'Not covered'}/>}
              />
            </td>
            {plans.map(plan => <td>{plan.foreignTravelEmergency}</td>)}
          </tr>
          <tr className={'delimeter'}>
            <td ></td>
            {plans.map(plan => <td></td>)}
          </tr>
        </tbody>
      </table>
  </Box>;
}

export default CategoryTable;

export const OMValue = (props: {title: string | JSX.Element, value: string, subtitle?: string | JSX.Element, subtitleClassName?: string}) => (
  <div>
    <Typography className={'dark-green medium fs-14 lh-17 mb-4'}>{props.title}</Typography>
    <OMVal value={props.value} subtitle={props.subtitle} subtitleClassName={props.subtitleClassName} skipMt/>
  </div>
)

export const OMVal = (props: {value: string | JSX.Element, subtitle?: string | JSX.Element, subtitleClassName?: string, skipMt?: boolean}) => (
  <Box mt={props.skipMt ? undefined : '22px'}>
    <Typography className={'fs-16 lh-20 medium'}>{props.value}</Typography>
    {props.subtitle && <Typography className={'fs-13 lh-16 ' + (props.subtitleClassName || '')}>{props.subtitle}</Typography>}
  </Box>
)

type TooltipedProps = {
  content: JSX.Element,
  tooltip: string
}

const Tooltiped = (props: TooltipedProps) => {
  return <Tooltip title={props.tooltip} arrow>
    <Box className={'flex'} sx={{display: 'flex', gap: '4px'}}>
      <img className={'w-14 h-14 mt-1'} src={'/assets/img/info.svg'}></img>
      <div>
        {props.content}
      </div>
    </Box>
  </Tooltip>
}

const StyledButton = styled(IconButton)({
  position: 'absolute',
  zIndex: 1,
  top: '3px',
  background: '#FF7862',
  width: 30,
  height: 30,
  boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  '&:hover': {
    background: '#FF551F',
  },
  '&:active': {
    background: '#FF551F',
  },
})
