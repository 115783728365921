import React from "react";
import {AppStateActionTypes, AppStateContext} from "../shared/AppStateContext";
import { createStyles, makeStyles, withStyles } from '@mui/styles';
import { Button, styled, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { environment } from '../environments/environment';
import ZipInput from '../shared/ZipInput';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      width: 125,
      height: 24,
      maxWidth: 125,
      maxHeight: 24,
    },
  }),
);

const Home = () => {
  const classes = useStyles();
  const [{currentStep}, dispatch] = React.useContext(AppStateContext);
  const [values, setValues] = React.useState<any>({});
  const theme = useTheme();
  const phone = useMediaQuery(theme.breakpoints.down('md'));

  const onSubmit = React.useCallback((data?: any) => {
    const d = data ? data : values;
    dispatch({type: AppStateActionTypes.UPDATE, payload: {...d, currentStep: 1}})
  }, [values])

  return <div className={!phone ? 'crw-flex' : ''}>
    <div className={'crw-flex-column'}>
      <Typography className={'crw-dark-green crw-fs-20 crw-medium'}>Looking for Medicare plans?</Typography>
      <Typography className={`crw-bold crw-fs-30 crw-lh-37 crw-mv-8`} color={'textPrimary'}>Easily compare all your options in one place.</Typography>
      <Typography className={`crw-fs-18 ${phone ? 'crw-mb-10' : ''}`} color={'textSecondary'}>Get personalized Medicare quotes in minutes</Typography>
    </div>
    <div>
      <ZipInput onSubmit={onSubmit} onChange={setValues} />
      <GetStartedButton variant={'rounded'} sx={{fontSize: 18, lineHeight: '31px'}} className={'crw-mb-10'}
                        disabled={!values.zip || !values.county}
                        onClick={() => onSubmit()}>Get started</GetStartedButton>
      <div className={'crw-flex crw-flex-align-center crw-flex-justify-end'}>
        <Typography className={'crw-fs-10 crw-mr-10'} color={'textPrimary'}>Powered by:</Typography>
        <img src={environment.imagesUrl + '/img/Logo.svg'} className={classes.logo} />
      </div>
    </div>
  </div>;
}


export default Home;

const GetStartedButton = withStyles({
  root: {
    transition: 'all 0.3s',
    width: '100%',
    whiteSpace: 'nowrap',
    '&:focus': {
    },
  },
  rounded: {
    backgroundColor: '#FF785A!important',
    boxShadow: 'none',
    borderRadius: 4,
    color: 'white!important',
    padding: '10px 0!important',
    '&:hover': {
      backgroundColor: '#FF551F!important',
      boxShadow: 'none',
    },
    '&:active': {
      backgroundColor: '#FF551F!important',
      boxShadow: 'none',
    },
    '&:disabled': {
      backgroundColor: '#FFC2B5!important',
      boxShadow: 'none',
    },
  },
  label: {
    fontWeight: 700,
    fontSize: 18,
    textTransform: 'none',
  },
})(Button);

