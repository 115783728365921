import { Button } from '@mui/material';
import { colors } from '@coverright/ui/themes';
import { withStyles } from '@mui/styles';


const BackButton = withStyles({
  root: {
    '&.MuiButton-text': {
      color: colors.text.primary,
    },
    '&:hover': {
      backgroundColor: 'transparent',
      '&.MuiButton-text': {
        color: colors.text.primary,
      }
    },
    textTransform: 'none'
  },
  startIcon: {
    marginTop: -1
  },
  label: {
    fontWeight: 700,
    color: colors.text.primary,
    marginTop: 3,
    textTransform: 'none'
  }
})(Button);

export default BackButton;
